export const featuredPortfolio = [
  {
    id: 1,
    title: "Blogging Web",
    img: "./assets/Pythonweb.png",
    link: "https://priyajanswongamikha.pythonanywhere.com/",
  },
  {
    id: 2,
    title: "demo app",
    img: "./assets/demoapp1.png",
    link: "https://github.com/Priyajan-collab/Demo-App",
  },
  {
    id: 3,
    title: "Manim Graph",
    img: "./assets/manimgraph.png",
    link: "https://youtu.be/eri-qNOPVUU",
  },
  {
    id: 4,
    title: "Face Detection",
    img: "./assets/facedetection.jpg",
    link: "https://github.com/Priyajan-collab/Face_detection",
  },
  {
    id: 5,
    title: "Irismodel",
    img: "./assets/irisdataset.png",
    link: "https://youtu.be/tZIPmKMvW6g",
  },
  {
    id: 6,
    title: "Plotting map with py",
    img: "./assets/plottingmap.png",
    link: "https://youtu.be/ovwhl1qR1xw",
  },
];

export const webPortfolio = [
  {
    id: 1,
    title: "Social Media App",
    img: "./assets/InstagramClone.png",
    link: "https://priyajanswongamikha.web.app/",
  },
  {
    id: 2,
    title: "Blogging Web",
    img: "./assets/Pythonweb.png",
    link: "https://priyajanswongamikha.pythonanywhere.com/",
  },
];

export const mobilePortfolio = [
  {
    id: 1,
    title: "demo app",
    img: "./assets/demoapp1.png",
    link: "https://github.com/Priyajan-collab/Demo-App",
  },
  {
    id: 2,
    title: "Music Player",
    img: "./assets/musicplayer.png",
    link: "https://github.com/Priyajan-collab/Musicplayer",
  },
  {
    id: 3,
    title: "Summarizer",
    img: "./assets/Summariser.png",
    link: "https://github.com/Priyajan-collab/Summarizer",
  },
  {
    id: 4,
    title: "Clock",
    img: "./assets/Clock.png",
    link: "https://github.com/Priyajan-collab/Clock--python--beginner-",
  },
];

export const manimPortfolio = [
  {
    id: 1,
    title: "Manim Graph",
    img: "./assets/manimgraph.png",
    link: "https://youtu.be/eri-qNOPVUU",
  },
  {
    id: 2,
    title: "Manim exploding circle",
    img: "./assets/manimcircleexploding.png",
    link: "https://youtu.be/I3gZPcewUEM",
  },
  {
    id: 3,
    title: "Manim object falling down",
    img: "./assets/manimcirclefallingdown.png",
    link: "https://youtu.be/4X5DNQGTsZw",
  },
];

export const machinelearningPortfolio = [
  {
    id: 1,
    title: "Face Detection",
    img: "./assets/facedetection.jpg",
    link: "https://github.com/Priyajan-collab/Face_detection",
  },
  {
    id: 2,
    title: "Irismodel",
    img: "./assets/irisdataset.png",
    link: "https://youtu.be/tZIPmKMvW6g",
  },
  {
    id: 3,
    title: "Plotting map with py",
    img: "./assets/plottingmap.png",
    link: "https://youtu.be/ovwhl1qR1xw",
  },
];
