import "./testimonials.scss";

export default function Testimonials() {
  const data = [
    {
      id: 1,
      name: "Damodar Pokhrel",
      title: "Project Manager at SEDS Nepal",
      img:
      "./assets/DamodarDai.jpg",
      icon: "assets/SedsNepal.jpg",
      desc:
      "Passionate ,hardworker and able to adapt to difficult situation",
      link:
      "https://damodarpokhrel.com.np/",
    },
    {
      id: 2,
      name: "Joon Shakya",
      title: "FreeLancer",
      img:
      "./assets/JoonDai.jpg",
      icon: "assets/youtube.png",
      desc:
      "Passionate,Quick learner and Very kind friend",
      featured: true,
      link:
      "https://www.joonshakya.com.np/",
      youtubelink:
      "https://www.youtube.com/c/JoonShakya"
    },

    {
      id: 3,
      name: "Rahul Ranjan Shah",
      title: "IAO(bronze medalist)",
      img:
      "./assets/RahulDai.jpg",
      icon: "assets/Astronomy.jpg",
      desc:
      "Priyajan is one of the most creative and thought provoking individual hinged with understanding subtleties via artificial lens.",
      link:
        "https://rahulranjansah.com.np/",
      },
  ];
  return (
    <div className="testimonials" id="testimonials">
      <h1>Testimonials</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img src="assets/right-arrow.png" className="left" alt="" />
              <img
                className="user"
                src={d.img}
                alt=""
              /><a href={d.youtubelink}>
              <img className="right" src={d.icon} alt="" />
              </a>
            </div>
            <div className="center">
              {d.desc}
            </div>
            <div className="bottom">
            <a href={d.link}><h3>{d.name}</h3></a>
              <h4>{d.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
