import { useState } from "react";
import "./contact.scss";
import emailjs from '@emailjs/browser';



export default function Contact() {
  const [message, setMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_cyulmsb', 'template_nnn18mf', e.target, 'user_dcZ8nj53mFMvUNQSR05YG')   
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
    setMessage(true);
  };
  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Contact.</h2>
        <form  onSubmit={handleSubmit}>
          <input type="text" placeholder="Email"  name="email"/>
          <input type="text" placeholder="name"  name="name"/>
          <textarea placeholder="Message" name="message"></textarea>
          <button type="submit">Send</button>
          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>
      </div>
    </div>
  );
}
